.Input {
    --input-height: 36px;
    width: 100%;
    position: relative;
    z-index: 1;
}

.Input > input {
    width: 100%;
    outline: none;
    background: white;
    border-radius: 58px;
    border: 2px solid rgb(var(--color-gray));
    animation: input-border-appearance linear .25s;
    padding: 24px 40px;
    font: 400 24px var(--font-Roboto);
    appearance: none;
    --webkit-appearance: none;
}


.Input > label,
.Input > input::placeholder {
    font: 400 24px var(--font-Roboto);
    color: rgb(var(--color-gray));
}

/* Components for input */
.Input > label {
    position: absolute;
    top: 34px;
    left: 10px;
    transition: top ease .25s;
    z-index: -1;
    max-width: 95%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.Input > .Input__loading,
.Input > .Input__support {
    position: absolute;
    top: 25px;
    right: 10px;
    font: 300 12px var(--font-CeraPro);
    width: auto;
    height: 34px;
    display: flex;
    align-items: center;
    color: rgb(var(--color-basic));
    background: linear-gradient(90deg,
    rgba(var(--color-white), 0) 0%,
    rgba(var(--color-white), 1) 20%,
    rgba(var(--color-white), 1) 100%);
    padding: 0 0 0 10px;
    border-radius: 4px;
}

.Input > .Input__loading {
    cursor: wait;
    font-size: 18px;
}

.Input > .Input__hint,
.Input > .Input__error {
    position: absolute;
    bottom: calc(100% + 10px);
    font: 300 14px var(--font-CeraPro);
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
}

.Input > .Input__title {
    position: absolute;
    top: calc(var(--input-height) + 24px);
    left: 0;
    right: 0;
    font: 300 12px var(--font-CeraPro);
    background: rgb(var(--color-white));
    color: rgb(var(--color-gray));
    padding: 6px;
    border-radius: 0 0 6px 6px;
    box-shadow: 0 4px 12px rgba(var(--color-text), 0.04);
    max-height: 96px;
    overflow-x: hidden;
    overflow-y: auto;
    word-break: break-all;
    cursor: pointer;
}

/* ScrollBar for Input__title */
.Input > .Input__title::-webkit-scrollbar {
    width: 6px;
}

.Input > .Input__title::-webkit-scrollbar-track {
    background: transparent;
}

.Input > .Input__title::-webkit-scrollbar-thumb {
    background: rgba(var(--color-gray), 0.4);
    border-radius: 6px;
}

.Input > .Input__title::-webkit-scrollbar-thumb:hover {
    background: rgba(var(--color-gray), 0.6);
}

.Input > .Input__hint {
    color: rgb(var(--color-gray));
}

.Input > .Input__error,
.Input > .Input__title--error {
    color: rgb(var(--color-red));
}

/* Input focus */
.Input > input:focus {
    border-color: rgb(var(--color-pink));
    /*border-bottom-color: rgb(var(--color-basic));*/
}

.Input--filled > label,
.Input > input:focus + label {
    top: 6px;
    font-size: 12px;
    font-weight: 300;
    color: rgb(var(--color-text));
}

/* Input disabled */
.Input--disabled > input:disabled {
    cursor: not-allowed;
    background-color: transparent;
    border-bottom-color: rgb(var(--color-gray));
}

.Input--disabled > .Input__hint,
.Input--disabled > .Input__error,
.Input--disabled > .Input__support,
.Input--disabled > input:disabled,
.Input--disabled > input:disabled + label {
    color: rgb(var(--color-gray));
    /* change color text in input */
    -webkit-text-fill-color: rgb(var(--color-gray));
}

/* Input error */
.Input--error > input {
    border-color: rgb(var(--color-red));
}

/* Input loading */
.Input--loading > .Input__support {
    display: none;
}

.Input--border > input {
    border: 2px solid #988E95;
}



@media screen and (max-width: 1023px) {
    .Input > input {
        padding: 15px 24px;
        font-size: 14px;
        line-height: 17px;
    }
    .Input > label,
    .Input > input::placeholder {
        font-size: 14px;
        line-height: 17px;
    }
}

@media screen and (max-width: 719px) {
    .Input > input {
        padding: 10px 24px;
        font-size: 12px;
        line-height: 15px;
    }
    .Input > label,
    .Input > input::placeholder {
        font-size: 12px;
        line-height: 15px;
    }
}

/* animation */
@-webkit-keyframes input-border-appearance {
    from {
        border-bottom-color: rgba(var(--color-gray), 0);
    }
    to {
        border-bottom-color: rgba(var(--color-gray), 1);
    }
}

@keyframes input-border-appearance {
    from {
        border-bottom-color: rgba(var(--color-gray), 0);
    }
    to {
        border-bottom-color: rgba(var(--color-gray), 1);
    }
}
