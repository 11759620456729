.Select {
    position: relative;
    height: 84px;
    z-index: 0;
}

/* Select__support */
.Select > .Input > .Input__support > .Select__support {
    background-color: transparent;
    outline: none;
    border: none;
    cursor: pointer;
    color: rgb(var(--color-basic-2));
    transform: rotate(0deg);
    transition: transform ease .25s;
    will-change: transform;

}

.Select--focused > .Input > .Input__support > .Select__support {
    transform: rotate(-180deg);
}

.Select > .Select__container {
    position: absolute;
    display: none;
    background-color: rgb(var(--color-white));
    top: 100%;
    left: 0;
    right: 0;
    padding: 4px;
    max-height: 164px;
    overflow-x: hidden;
    overflow-y: auto;
    box-shadow: 0 15px 25px #E6D8DE;
    border-radius: 24px;
}


/* Select focus */
.Select--focused {
    z-index: var(--layer-3);
}

.Select--focused > .Select__container {
    display: block;
    animation: select-appearance ease-in .15s;

}

/* ScrollBar */
.Select > .Select__container::-webkit-scrollbar {
    width: 2px;
}

.Select > .Select__container::-webkit-scrollbar-track {
    background: transparent;
}

.Select > .Select__container::-webkit-scrollbar-thumb {
    background: rgba(var(--color-gray), 0.4);
    border-radius: 2px;
}

.Select > .Select__container::-webkit-scrollbar-thumb:hover {
    background: rgba(var(--color-gray), 0.6);
}

.Select > .Select__container > .Select__item {
    background-color: transparent;
    color: rgb(var(--color-text));
    padding: 4px;
    margin-bottom: 4px;
    font: 400 14px var(--font-CeraPro);
    cursor: pointer;
}

.Select > .Select__container > .Select__item:last-child {
    margin-bottom: 0;
}

.Select > .Select__container > .Select__item--selected,
.Select > .Select__container > .Select__item:hover {
    background-color: rgba(var(--color-basic), .04);
    color: rgb(var(--color-basic));
}


/* animation */
@-webkit-keyframes select-appearance {
    from {
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        -webkit-transform: translateY(50%);
        transform: translateY(50%);
    }
    to {
        opacity: 1;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
        -webkit-transform: translateY(0%);
        transform: translateY(0%);
    }
}

@keyframes select-appearance {
    from {
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        -webkit-transform: translateY(50%);
        transform: translateY(50%);
    }
    to {
        opacity: 1;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
        -webkit-transform: translateY(0%);
        transform: translateY(0%);
    }
}


@media screen and (max-width: 1023px) {
    .Select {
        height: 51px;
    }
    .Select .Input__support {
        top: 50%;
        transform: translateY(-50%);
        background: unset;
    }
}