@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.cdnfonts.com/css/montserrat');

:root {

    /* color */
    --color-basic: 126, 15, 92;
    --color-basic-2: 75, 120, 242;
    --color-basic-3: 255, 122, 0;
    --color-basic-4: 255, 147, 47;
    --color-basic-hover: 0, 133, 255;
    --color-text: 22, 20, 43;
    --color-gray: 174, 177, 188;
    --color-gray-2: 210, 214, 225;
    --color-light-gray-1: 233, 236, 243;
    --color-light-gray-2: 243, 246, 250;
    --color-background: 251, 251, 251;
    --color-white: 255, 255, 255;
    --color-red: 255, 12, 62;
    --color-red-2: 250, 74, 106;
    --color-green: 34, 198, 164;
    --color-blue: 72, 167, 255;
    --color-purpule: 112, 11, 91;
    --color-pink: 238, 49, 107;

    /* gradients */
    --gradients-main: linear-gradient(180deg, rgb(var(--color-basic)) 0%, rgb(var(--color-basic-2)) 100%);

    /* state */
    --state-focus: 0 0 0 4px rgba(var(--color-basic-2), .4);
    --state-disabled: rgba(var(--color-light-gray-1), .4);

    /* shadow */

    --shadow-basic: 0 0 20px rgba(var(--color-text), 0.15);
    --shadow-main: 0px 8px 28px rgba(75, 133, 187, 0.6);
    --shadow-1: 0 4px 26px rgba(var(--color-text), 0.06);
    --shadow-2: 0 4px 26px rgba(var(--color-text), 0.09);
    --shadow-3: 0 4px 26px rgba(var(--color-text), 0.2);

    /* font */
    --font-Roboto: "Roboto", sans-serif;
    --font-Manrope: "Manrope", sans-serif;
    --font-CeraPro: "Cera Pro", sans-serif;
    --font-OCR-A-Extended: "OCR A Extended", sans-serif;;

    /* layer */
    --layer-1: 91;
    --layer-2: 92;
    --layer-3: 93;
    --layer-4: 94;
    --layer-5: 95;
    --layer-6: 96;
    --layer-7: 97;
    --layer-8: 98;
    --layer-9: 99;


    /* animation */
    --state-focus-animation: state-focus-animation ease-in .25s;
}
