.PaddingContainer {
    padding-left: 230px;
    padding-right: 230px;
}

@media screen and (max-width: 1919px){
    .PaddingContainer {
        padding-left: 80px;
        padding-right: 80px;
    }
}

@media screen and (max-width: 1440px){
    .PaddingContainer {
        padding-left: 24px;
        padding-right: 24px;
    }
}

@media screen and (max-width: 719px){
    .PaddingContainer {
        padding-left: 16px;
        padding-right: 16px;
    }
}