.Card {
    max-width: 374px;
    min-width: 288px;
    width: 100%;
    border-radius: 8px;
    display: grid;
    border: 1px solid rgb(var(--color-gray-2));
    justify-items: center;
    align-content: center;
    position: relative;
}


/* card state*/
.Card--loading::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: rgba(var(--color-white), 0.8);
    border-radius: inherit;
    cursor: progress;
}

.Card--loading::before {
    content: "";
    display: block;
    width: 34px;
    height: 34px;
    position: absolute;
    top: 50%;
    left: 50%;
    border: 2px solid rgb(var(--color-basic-2));
    border-bottom-color: transparent;
    transform: translate(-50%, -50%);
    animation: rotate linear infinite 1s;
    will-change: transform;
    z-index: 2;
    border-radius: 50%;
    cursor: progress;
}


.Card__form {
    max-width: 306px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
}

/* input layout */
.Card__form__input:nth-child(1) {
    width: 100%;
    flex-shrink: 0;
}

.Card__form__input:nth-child(2) {
    width: 78px;
}

.Card__form__input:nth-child(3) {
    width: 58px;
}

/* inputs */
.Card__form__input {
    position: relative;
    transition: border .25s ease-out;
    will-change: border;
}

.Card__form__input > input {
    width: 100%;
    height: 36px;
    text-align: center;
    border-radius: 2px;
    background: transparent;
    color: rgb(var(--color-text));
    border: 1px solid rgb(var(--color-gray-2));
    font: 400 20px var(--font-OCR-A-Extended);
    appearance: none;
}

/* input base state */
.Card__form__input > input:focus {
    border-color: rgb(var(--color-basic-2));
}

.Card__form__input > input::placeholder {
    color: rgb(var(--color-gray-2));
}

.Card__form__input > span {
    font: 400 12px var(--font-Roboto);
    color: rgb(var(--color-text));
    position: absolute;
    top: calc(36px + 2px);
    left: 0;
    right: 0;
    max-height: 36px;
    overflow-x: hidden;
    overflow-y: auto;
}

/* input error & required state */
.Card__form__input--error > input,
.Card__form__input > input:invalid {
    border-color: rgb(var(--color-red));
}

.Card__form__input--error > span {
    color: rgb(var(--color-red));
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

}


/* media */
@media screen and (min-width: 0) and (max-width: 599px)/* xs */ {
    .Card__form__input > input {
        font-size: 18px;
    }
}


/* animation */
@keyframes rotate {
    from {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    to {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}
