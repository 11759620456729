.Button {
    width: 100%;
    min-width: max-content;
    padding: 18px 30px;
    border-radius: 50px;
    background: rgb(var(--color-purpule));
    color: rgb(var(--color-white));
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    border: none;
    outline: none;
    cursor: pointer;
}


.Button:focus,
.Button--link:focus,
.Button--outline:focus {
    box-shadow: 0 8px 28px rgba(var(--color-purpule), 0.6), var(--state-focus);
}

/* States button */
/* Hover */
.Button:hover {
    opacity: 0.8;
}

.Button--link:hover,
.Button--outline:hover {
    color: rgba(var(--color-basic), 0.8);
    background-color: transparent;
}

.Button--outline:hover {
    border-color: rgba(var(--color-basic), 0.8);
}

/* Disabled */
.Button:disabled,
.Button--link:disabled,
.Button--outline:disabled {
    cursor: not-allowed;
}

.Button:disabled {
    background: rgb(var(--color-light-gray-1));
    box-shadow: none;
}

.Button--link:disabled,
.Button--outline:disabled {
    background: transparent;
    color: rgb(var(--color-gray-2));
    box-shadow: none;
}

.Button--outline:disabled {
    border-color: rgb(var(--color-light-gray-1))
}

/* Focus */
.Button--link:focus,
.Button--outline:focus {
    background: transparent;
    box-shadow: none;
}


/* Variants button */
.Button--link,
.Button--outline {
    background: transparent;
    box-shadow: none;
}

.Button--link {
    color: rgb(var(--color-basic));
    text-decoration: underline;
}

.Button--outline {
    color: rgb(var(--color-text));
    border: 1px solid rgb(var(--color-basic));
}

.Button--pink {
    background: rgb(var(--color-pink));
}

.Button--gradient {
    background: linear-gradient(86.51deg, #700B5B 0%, #EE316B 96.46%);
}

@media screen and (max-width: 1023px) {
    .Button {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

/* media */
@media screen and (min-width: 0) and (max-width: 599px)/* xs */ {
    .Button {
        font-size: 16px;
    }
}
