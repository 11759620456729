.Grid {
    --grid-column: 12;
    --grid-column-gap: 24px;
    width: 100%;

    display: grid;
    grid-template-columns: repeat(var(--grid-column), 1fr);
    grid-template-rows: auto;
    grid-column-gap: var(--grid-column-gap);
    /*padding: 0 var(--grid-column-gap);*/
}


@media screen and (
max-width: 1023px /* breakpoint-mobile */
) {
    .Grid {
        --grid-column: 8;
    }
}

@media screen and (
max-width: 719px /* breakpoint-mobile */
) {
    .Grid {
        --grid-column: 4;
        --grid-gap: 16;
    }
}

